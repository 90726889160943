import { Suspense } from "react";
import Loader from "../common/loader";
import { Layout } from "antd";
import { Login } from "modern-auth";
import CommonNavbar from "../common/navbar";
import { Routes, Route, Navigate } from "react-router-dom";
import DarkLogo from "../assets/dataOS-logo-common-2.svg";
import Page404 from "../common/PageNotFound";
import UserProfile from "../components/UserProfile";
import Directory from "../components/directory";

const { Header, Content } = Layout;

const RedirectToLandingPage = () => {
  return <Navigate to="/" />;
};

export const Page = (props: any) => {
  const { children } = props;
  return (
    <Suspense
      fallback={
        <Content>
          <Loader padding="0px" />
        </Content>
      }
    >
      {children}
    </Suspense>
  );
};

const RenderPage = (props: any) => {
  const {
    user,
    auth,
    message,
    value,
    toggleTheme,
    profileDetail,
    setProfileDetail,
    setNotFound,
    currentTheme,
    features,
  } = props;

  if (auth.isLoading) {
    return (
      <Content>
        <Loader padding="0px" />
      </Content>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <Page>
        <Login logo={DarkLogo} message={message} />
      </Page>
    );
  }

  return (
    <Page>
      {auth.isAuthenticated && value ? (
        <Header className="header container">
          <CommonNavbar toggleTheme={toggleTheme} />
        </Header>
      ) : (
        <></>
      )}

      <Content
        style={{
          padding: "30px 20px 0px",
          height: "100%",
          width: "100%",
          maxWidth: 1440,
          margin: "0px auto",
        }}
      >
        <Routes>
          <Route path="/auth/callback" element={<RedirectToLandingPage />} />
          <Route
            path="/"
            element={auth.isAuthenticated && user && <Directory />}
          />
          <Route
            path="/apps"
            element={auth.isAuthenticated && <Navigate to={`/`} />}
          />
          <Route
            path="/profile"
            element={<Navigate to={`/profile/overview/${value!.id}`} />}
          />
          <Route
            path="/profile/:userId"
            element={
              auth.isAuthenticated && (
                <UserProfile
                  profileDetail={profileDetail}
                  setProfileDetail={setProfileDetail}
                />
              )
            }
          />
          <Route
            path="/profile/overview/:userId"
            element={
              auth.isAuthenticated && (
                <UserProfile
                  profileDetail={profileDetail}
                  setProfileDetail={setProfileDetail}
                />
              )
            }
          />
          <Route
            path="/profile/tokens/:userId"
            element={
              auth.isAuthenticated && (
                <UserProfile
                  profileDetail={profileDetail}
                  setProfileDetail={setProfileDetail}
                />
              )
            }
          />
          <Route
            path="/profile/usage/:userId"
            element={
              auth.isAuthenticated && (
                <UserProfile
                  profileDetail={profileDetail}
                  setProfileDetail={setProfileDetail}
                />
              )
            }
          />
          {features && features?.homeProvenanceEnable === true ? (
            <Route
              path="/profile/provenance/:userId"
              element={
                auth.isAuthenticated && (
                  <UserProfile
                    profileDetail={profileDetail}
                    setProfileDetail={setProfileDetail}
                  />
                )
              }
            />
          ) : null}
          {value && (
            <Route
              path={"*"}
              element={
                <Page404
                  currentTheme={currentTheme}
                  setNotFound={setNotFound}
                  showLogo
                />
              }
            />
          )}
        </Routes>
      </Content>
    </Page>
  );
};

export default RenderPage;

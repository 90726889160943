import React from 'react';
import ReactDOM from 'react-dom/client'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './css/Custom.scss';
import { APP_ENV } from './utils/Const';
import App from './App';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

function noop() {}
if (APP_ENV === 'production') {
    console = console || {};
    console.log = noop;
    console.warn = noop;
    // console.error = noop; //to enable errors in production env
}

axios.interceptors.request.use((request)=>{
    let { headers } = request
    headers = {...headers, 'dataos-correlation-id': uuidv4()}
    return {...request, headers:headers};
  })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
export {}
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
